import { Injectable, Injector, ApplicationRef, createNgModule, createComponent, EmbeddedViewRef } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin, from } from 'rxjs';
import { take } from 'rxjs/operators';
import { BASE_DATE_PICKER_COMPONENT_INJECTION_TOKEN, BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN, UtilDatePickerOptions, UtilDatePickerRef } from './util-date-picker-types';
import { WindowRef } from '@spartacus/core';


@Injectable({
  providedIn: 'root'
})
export class UtilDatePickerService {

  activeRef$ = new BehaviorSubject<UtilDatePickerRef>(null);

  constructor(
    private windowRef: WindowRef,
    private applicationRef: ApplicationRef
  ) {}

  open(options: UtilDatePickerOptions): UtilDatePickerRef {

    if (this.activeRef$.value) {
      return null;
    }

    if (typeof options.closableByEscape !== 'boolean') {
      options.closableByEscape = true;
    }
    if (typeof options.closeableWithBackdraftClick !== 'boolean') {
      options.closeableWithBackdraftClick = true;
    }

    options.datePickerRef = new UtilDatePickerRef();

    this.createDatePicker(options);

    this.activeRef$.next(options.datePickerRef);

    options.datePickerRef.waitForResult().pipe(take(1)).subscribe(_ => this.activeRef$.next(null));

    return options.datePickerRef;
  }

  private createDatePicker(options?: UtilDatePickerOptions) {

    const whenDoneSubject = new Subject<any>();

    // create promises of the module and the component, which will be created dynamically
    const modalModulePromise = import('./util-date-picker/util-date-picker.module').then(m => m.UtilDatePickerModule);
    const modalComponentPromise = import('./util-date-picker/util-date-picker.component').then(c => c.UtilDatePickerComponent);
    // then promise into observable
    const cmpObs = from(modalComponentPromise);
    const moduleObs = from(modalModulePromise);

    // load them
    forkJoin(
      [
        cmpObs.pipe(take(1)),
        moduleObs.pipe(take(1))
      ]).pipe(take(1))
    .subscribe(([cmp, module]) => {

      const environmentInjector = this.applicationRef.injector;

      // should load and activate all imports
      const createdModule = createNgModule(module, options.injector || environmentInjector);

      const optionsComponentInjector = Injector.create({
        providers: [
          {
            provide: BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN,
            useValue: options
          },
        ],
        name: 'inner-component-injector',
        // parent: options.injector
          parent: createdModule.injector
      });

      // create injector of the outer component, which is the backdraft,
      const modalComponentInjector = Injector.create({
        providers: [
          {
            provide: BASE_DATE_PICKER_COMPONENT_INJECTION_TOKEN,
            useValue: {
              component: cmp,
              injector: optionsComponentInjector
            }
          },
          {
            provide: BASE_DATE_PICKER_OPTIONS_INJECTION_TOKEN,
            useValue: options
          },
        ],
        name: 'util-modal-component-injector',
        parent: options.injector
      });

      // create the component
      const createdComponentRef = createComponent(cmp!, {
        elementInjector: modalComponentInjector,
        environmentInjector: environmentInjector
      });

      // set the ref in the UnitModalRef
      options.datePickerRef.setComponentRef(createdComponentRef as any);

      // add component to the DOM
      this.applicationRef.attachView(createdComponentRef.hostView);
      const host = (createdComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.windowRef.document.body.appendChild(host);

      // say that dynamical creation is done
      whenDoneSubject.next(createdComponentRef);
      // and complete
      whenDoneSubject.complete();

      // waits for the next popstate event (history back or forward) and closes the modal
      // stops waiting when any kind of result is returned
      // waitForNextHistoryJump(() => options.modalRef.close(), options.modalRef.waitForResult());

    });

    return whenDoneSubject;
  }

}


export class UtilDatePickerTestingService {

  activeRef$ = new BehaviorSubject<UtilDatePickerRef>(null);

  open(options: UtilDatePickerOptions): UtilDatePickerRef {
    if (this.activeRef$.value) {
      return null;
    }
    options.datePickerRef = new UtilDatePickerRef();
    this.activeRef$.next(options.datePickerRef);
    return options.datePickerRef;
  }

}
