import { TranslationService } from '@spartacus/core';
import { Observable, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';


export function getOneTimeTranslations(translationService: TranslationService, keyArr: string[]): Observable<string[]> {

  const obs = keyArr.map(key => (translationService.translate(key).pipe(filter(_ => !!_), take(1))));
  return forkJoin(obs);

}

// TODO // NOSONAR
// export function addTranslationDynamically(lang: string, key: string, translation: string) {}
