
<div *ngIf="!labelless" class="input-label-container">
  <label *ngIf="!labelless" [attr.for]="uniqueID">{{label}}</label>
  <ng-container *ngIf="tooltip || showInfoIcon">
    <app-icon
      tabindex="0"
      iconType="INFO"
      iconSize="EXTRA_SMALL"
      [colorTheme]="['BASE']"
      [kurzTooltip]="tooltip"
      [kurzTooltipTemplate]="tooltipTemplate"
      [kurzTooltipPosition]="['top', 'top-right', 'right']"
      (activateElement)="clickInfo($event)"
    ></app-icon>
  </ng-container>
</div>

<ng-container>

  <ng-container *ngIf="textonly" #textonlyValue>
    <div class="textonly app-input-value">{{value}}</div>
  </ng-container>

  <ng-container *ngIf="!textonly" #normalTemplate>
    <div [ngClass]="['app-input-wrapper', (labelTransformClass$ | async)]" class="">

      <ng-container>

        <ng-container *ngIf="!valueTemplate && !renderingData">
          <input
            [attr.type]="(type === 'date' || readonly) ? 'text' : type"
            [attr.autocomplete]="(cancelBrowserAutocomplete || type === 'date' || type === 'number') ? 'off' : (autocomplete || 'on')"
            [attr.name]="name"
            [attr.placeholder]="placeholder"
            [attr.min]="min"
            [attr.max]="max"
            [attr.step]="step"
            [attr.aria-description]="ariaDescription"
            class="app-input-value"
            [attr.id]="uniqueID"
            [formControl]="formControl"
            [attr.readonly]="readonly"
            (focus)="handleDateFocus($event)"
            (blur)="handleDateBlur($event)"
            (keydown.enter)="onKeydownOfInput($event)"
            (input)="inputFunction($event)"
          >
        </ng-container>

        <ng-container *ngIf="valueTemplate">
          <ng-container *ngTemplateOutlet="valueTemplate; context: {$implicit: value}"></ng-container>
        </ng-container>

        <ng-container *ngIf="renderingData" #renderingDataTemplate>
          <ng-container
            [ngComponentOutlet]="renderingData.component"
            [ngComponentOutletInjector]="renderingData.__renderingInjector"
          ></ng-container>
        </ng-container>
      </ng-container>

      <div class="app-input-text-length" *ngIf="showLength">
        <div>{{getTextLengthString()}}</div>
      </div>

      <div *ngIf="iconType !== 'none'" class="app-input-icon-wrapper" (activateElement)="clickInnerIcon()">
        <app-icon
          [tabindex]="((removeInnerIconFromTabOrder$ | async) ? '-1' : '0')"
          [iconType]="customIconType"
          iconSize="EXTRA_SMALL"
        ></app-icon>
      </div>

      <div *ngIf="displayCalendarIconFirefox" class="firefox-calendar-icon">
        <app-icon
          tabindex="0"
          iconType="CALENDAR"
          iconSize="EXTRA_SMALL"
        ></app-icon>
      </div>

      <div *ngIf="showDynamicLabel" class="dynamic-input-label">{{ placeholder }}</div>
    </div>
  </ng-container>

</ng-container>



<ng-container *ngIf="errorMessage$ | async as message">
  <div class="app-input-error-warning">{{message}}</div>
</ng-container>

