import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Injector, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, TemplateRef, forwardRef, inject } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, take } from 'rxjs/operators';
import { BaseFormComponent } from '../shared/base-form/base-form.component';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';
import { InputDateRenderingComponent, InputDefaultRenderingComponent } from './input-default-rendering.component';
import { INPUT_DEFAULT_RENDERING_INJECTION_TOKEN, InputRenderingData } from './input-rendering.types';
import type { UtilDatePickerPageCell } from '@util/services/util-date-picker-types';
import { AccessabilityComponentObject } from '@util/types/shared.types';
import { UtilElementHelper } from '@util/functions/elements';

/**
 * setting a value to min can cause the step to be off
 * Example: min=1, step=5 causes the user to step through 1, 6, 11, ...
 * 'valueBiggerEqualToOne' step=5 would remedy the input to be off but makes input
 */
export type InputRestriction = 'noSingleZero' | 'noUpperCaseLetters' | 'noLowerCaseLetters' | 'valueSmallerThanMin' | 'valueGreaterThanMax' | 'valueBiggerEqualToOne';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  exportAs: 'app-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends BaseFormComponent implements OnInit, AfterContentInit, OnDestroy, ColorThemableComponent {

  private elementRef = inject(ElementRef);
  private utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  private ngZone = inject(NgZone);
  private injector = inject(Injector);
  private renderer2 = inject(Renderer2);

  private static _ID = 0;
  private _uniqueID = '__InputComponent_' + (InputComponent._ID++);
  private _tooltip: string;
  private _oldValue: string;

  private _placeholderBS = new BehaviorSubject<string>('');
  private _debounceTime: number;
  private _showLength: boolean;
  private _step: string;
  private _textonly: boolean;
  private _readonly: boolean;
  private _disabled: boolean;
  private _labelless: boolean;
  private _narrow: boolean;
  private _truncateValue: boolean;
  private _customIconType: string;
  private _showDynamicLabel: boolean;
  private _showInfoIcon: boolean;
  private _inputRestrictions: InputRestriction[] = [];
  private _inputRenderingData: InputRenderingData;
  private _cancelBrowserAutocomplete: boolean;
  private _disableWeekend: boolean;

  private typeChangeSubscription: Subscription;

  private _tooltipTemplate: TemplateRef<any>;
  private _valueTemplate: TemplateRef<any>;

  private labelTransformClassBehaviorSubject = new BehaviorSubject<'dynamic-label-transform' | ''>('');
  protected removeInnerIconFromTabOrder$ = new BehaviorSubject<boolean>(false);

  get labelTransformClass$(): Observable<string> {
    return this.labelTransformClassBehaviorSubject.asObservable();
  }

  get uniqueID(): string {
    return this._uniqueID;
  }


  @HostBinding('class')
  private get classes() {
    const arr = ['app-input'];

    let invalid = false;
    this.externalErrors$.pipe(take(1)).subscribe(errors => {
      const errorKeys = Object.keys(errors || {}).filter(_ => !!errors?.[_]);
      invalid = errorKeys.length > 0 || this.formControl.invalid;
    });

    arr.push('app-input-' + (this.formControl?.untouched ? 'untouched' : 'touched'));
    arr.push('app-input-' + (this.formControl?.dirty ? 'dirty' : 'pristine'));
    arr.push('app-input-' + (invalid ? 'invalid' : 'valid'));

    if (this.hasRequiredValidator) {
      arr.push('app-input-required');
    }

    if (this.narrow) {
      arr.push('app-input-narrow');
    }

    if (this.truncateValue) {
      arr.push('app-input-truncate-value');
    }

    if (this.iconType !== 'none') {
      arr.push('app-input-inner-icon');
      arr.push('app-input-inner-icon-' + this.iconType);
    }

    if (!this.value) {
      arr.push('app-input-empty');
    }

    if (this.disabled) {
      arr.push('app-input-disabled');
    }

    arr.push('app-input-align-' + this.align);

    return arr;
  }


  @Input()
  min: string;


  @Input()
  max: string;

  @Input()
  set showLength(value: boolean) {
    this._showLength = coerceBoolean(value);
  }

  get showLength(): boolean {
    return this._showLength;
  }


  @Input()
  set step(value: string) {
    this._step = value;
  }

  get step(): string {
    return this._step;
  }


  @Input()
  set value(value: string) {
    if (this.value !== value) {
      this.formControl.setValue(value);
    }
    this.testIfTransformNeeded();
  }

  get value(): string {
    return this.formControl.value as string;
  }

  @Input()
  set tooltip(value: string) {
    this._tooltip = value;
  }

  get tooltip(): string {
    return this._tooltip;
  }

  @Input()
  set tooltipTemplate(value: TemplateRef<any>) {
    this._tooltipTemplate = value;
  }

  get tooltipTemplate(): TemplateRef<any> {
    return this._tooltipTemplate;
  }


  @Input()
  set valueTemplate(value: TemplateRef<any>) {
    this._valueTemplate = value;
  }

  get valueTemplate(): TemplateRef<any> {
    return this._valueTemplate;
  }


  @Input()
  set renderingData(value: InputRenderingData<any>) {
    if (value && !value.__renderingInjector) {
      if (!value.component) {
        value.component = InputDefaultRenderingComponent;
      }
      value.__renderingInjector = Injector.create({
        providers: [
          {provide: INPUT_DEFAULT_RENDERING_INJECTION_TOKEN, useValue: value.data},
          {provide: InputComponent, useValue: this},
        ],
        parent: this.injector
      });
    }
    this._inputRenderingData = value;
  }

  get renderingData(): InputRenderingData<any> {
    return this._inputRenderingData;
  }

  @Input()
  set removeInnerIconFromTabOrder(value: boolean) {
    this.removeInnerIconFromTabOrder$.next(coerceBoolean(value));
  }


  @Input()
  set textonly(value: boolean) {
    this._textonly = coerceBoolean(value);
  }

  get textonly(): boolean {
    return this._textonly;
  }

  @Input()
  set cancelBrowserAutocomplete(value: boolean) {
    this._cancelBrowserAutocomplete = coerceBoolean(value);
  }

  get cancelBrowserAutocomplete(): boolean {
    return this._cancelBrowserAutocomplete;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBoolean(value);

    window?.setTimeout?.(() => {
      const host = this.elementRef?.nativeElement as HTMLElement;
      const input = host?.querySelector('input');
      if (this._readonly) {
        input?.setAttribute('readonly', 'true');
      } else {
        input?.removeAttribute('readonly');
      }
    });
  }

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBoolean(value);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set labelless(value: boolean) {
    this._labelless = coerceBoolean(value);
  }

  get labelless(): boolean {
    return this._labelless;
  }

  @Input()
  set narrow(value: boolean) {
    this._narrow = coerceBoolean(value);
  }

  get narrow(): boolean {
    return this._narrow;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholderBS.next(value);
  }

  get placeholder(): string {
    return this._placeholderBS.value;
  }

  get placeholder$(): Observable<string> {
    return this._placeholderBS.asObservable();
  }

  @Input()
  set inputRestrictions(value: InputRestriction | InputRestriction[]) {
    this._inputRestrictions = Array.isArray(value) ? value : [value];
  }

  get inputRestrictions(): InputRestriction[] {
    return this._inputRestrictions;
  }

  private inputFunction = (e: InputEvent) => {
    const newValue = (e?.target as HTMLInputElement)?.value;

    // Internal Bug COM-207, external Firefox Bug https://bugzilla.mozilla.org/show_bug.cgi?id=1012818
    if (document.activeElement !== e.target) {
      (e.target as HTMLInputElement)?.focus();
    }

    if (this.inputRestrictions.includes('noSingleZero')) {
      if (newValue === '0') {
        this.value = '';
      }
    }

    if (this._inputRestrictions.includes('noUpperCaseLetters')) {
      if (newValue && /[A-Z]/.test(newValue)) {
        this.value = newValue.toLocaleLowerCase();
      }
    }

    if (this._inputRestrictions.includes('noLowerCaseLetters')) {
      if (newValue && /[a-z]/.test(newValue)) {
        this.value = newValue.toLocaleUpperCase();
      }
    }

    if (this._inputRestrictions.includes('valueSmallerThanMin')) {

      if (this.type === 'text' && this.min) {
        const valueLength = this.value?.length || 0;
        const minLength = parseFloat(this.min);

        if (valueLength < minLength && valueLength < this._oldValue?.length) {
          this.value = this._oldValue;
        }
      }

      if (this.type === 'number' && this.min) {
        const newNumber = parseFloat(newValue);
        const minNumber = parseFloat(this.min);

        if (minNumber > newNumber) {
          this.value = this.min;
        }
      }

      if (this.type === 'date' && this.min) {
        const newDate = new Date(newValue);
        const minDate = new Date(this.min);

        // input restrictions are only tested after the user has input at least 4 digits for the year hence the full year needs to be greater equal than 1000
        if (newDate.getFullYear() >= 1000 && minDate.getTime() > newDate.getTime()) {
          this.value = minDate.toISOString();
        }
      }

      if (this.type !== 'date' && this.type !== 'number' && this.type !== 'text') {
        console.warn('input restriction \'valueSmallerThanMin\' is not suitable for this type of app-input (' + this.type + ')');
      }
    }

    if (this._inputRestrictions.includes('valueGreaterThanMax')) {

      if (this.type === 'text' && this.max) {
        const valueLength = this.value?.length || 0;
        const maxLength = parseFloat(this.max);

        if (valueLength > maxLength) {
          this.value = this.value?.slice(0, maxLength);
        }
      }

      if (this.type === 'number' && this.max) {
        const newNumber = parseFloat(newValue);
        const maxNumber = parseFloat(this.max);

        if (maxNumber < newNumber) {
          this.value = this.max;
        }
      }

      if (this.type === 'date' && this.max) {
        const newDate = new Date(newValue);
        const maxDate = new Date(this.max);

        // input restrictions are only tested after the user has input at least 4 digits for the year hence the full year needs to be greater equal than 1000
        if (newDate.getFullYear() >= 1000 && maxDate.getTime() < newDate.getTime()) {
          this.value = maxDate.toISOString();
        }
      }

      if (this.type !== 'date' && this.type !== 'number' && this.type !== 'text') {
        console.warn('input restriction \'valueGreaterThanMax\' is not suitable for this type of app-input (' + this.type + ')');
      }
    }

    if (this.inputRestrictions.includes('valueBiggerEqualToOne')) {
      const tmp = parseFloat(newValue);
      if (Number.isNaN(tmp) || tmp < 1) {
        this.value = '1';
      }
    }

    this._oldValue = this.value;
    this.inputText.emit(e);

  };

  @Input()
  set truncateValue(value: boolean) {
    this._truncateValue = coerceBoolean(value);
  }

  get truncateValue(): boolean {
    return this._truncateValue;
  }


  @Input()
  set debounceTime(value: number) {
    this._debounceTime = (typeof value === 'string') ? parseInt(value, 10) : value;
  }

  get debounceTime(): number {
    return this._debounceTime;
  }

  @Output()
  readonly valueChange = this.formControl.valueChanges;

  @Output()
  readonly debounce = this.formControl.valueChanges.pipe(debounce(() => timer(this.debounceTime)));

  @Output()
  readonly focus = new EventEmitter<FocusEvent>();

  @Output()
  readonly blur = new EventEmitter<FocusEvent>();

  @Output()
  readonly custom = new EventEmitter<string>();

  @Output()
  readonly info = new EventEmitter<UIEvent>();

  @Output()
  readonly inputText = new EventEmitter<InputEvent>();

  @Output()
  readonly picked = new EventEmitter<UtilDatePickerPageCell>();

  @Input()
  iconType: 'none' | 'nullify' | 'clear' | 'custom' = 'none';

  @Input()
  name: string;

  @Input()
  autocomplete: string;

  /**
   * makes the weekends unselectable if the type of the input field is date
   */
  @Input()
  set disableWeekend(value: boolean) {
    this._disableWeekend = coerceBoolean(value);
  }

  get disableWeekend(): boolean {
    return this._disableWeekend;
  }

  get customIconType(): string {
    if (this.iconType === 'custom') {
      return this._customIconType;
    } else {
      return this.iconType === 'nullify' ? 'CLOSE' : 'CLOSE';
    }
  }

  @Input()
  set customIconType(value: string) {
    this._customIconType = value;
  }

  get showDynamicLabel(): boolean {
    return this._showDynamicLabel;
  }

  @Input()
  set showDynamicLabel(value: boolean) {
    this._showDynamicLabel = coerceBoolean(value);
  }

  get showInfoIcon(): boolean {
    return this._showInfoIcon;
  }

  @Input()
  set showInfoIcon(value: boolean) {
    this._showInfoIcon = coerceBoolean(value);
  }

  get displayCalendarIconFirefox(): boolean {
    return this.type === 'date' && window.navigator.userAgent.indexOf('Firefox') > -1;
  }

  get aco(): AccessabilityComponentObject {
    return null;
  }

  @Input()
  set aco(value: AccessabilityComponentObject) {

    const host = this.elementRef.nativeElement as HTMLElement;

    UtilElementHelper.waitForChild(host, 'input').pipe(take(1)).subscribe(inputEl => {
      if (value.role) {
        this.renderer2.setAttribute(inputEl, 'role', value.role);
      } else {
        this.renderer2.removeAttribute(inputEl, 'role');
      }

      if (value.ariaControls) {
        this.renderer2.setAttribute(inputEl, 'aria-controls', value.ariaControls);
      } else {
        this.renderer2.removeAttribute(inputEl, 'aria-controls');
      }

      if (value.ariaExpanded) {
        this.renderer2.setAttribute(inputEl, 'aria-expanded', value.ariaExpanded);
      } else {
        this.renderer2.removeAttribute(inputEl, 'aria-expanded');
      }

      if (value.ariaHaspopup) {
        this.renderer2.setAttribute(inputEl, 'aria-haspopup', value.ariaHaspopup);
      } else {
        this.renderer2.removeAttribute(inputEl, 'aria-haspopup');
      }

      if (value.ariaActivedescendant) {
        this.renderer2.setAttribute(inputEl, 'aria-activedescendant', value.ariaActivedescendant);
      } else {
        this.renderer2.removeAttribute(inputEl, 'aria-activedescendant');
      }
    });

  }

  constructor() {
    super();
    this.debounceTime = 500;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.type ||= 'text';
    this._oldValue = this.value;

    this.typeChangeSubscription = this.type$.pipe(distinctUntilChanged()).subscribe(type => {

      if (type === 'date') {
        this.renderingData = {
          component: InputDateRenderingComponent,
          data: {
            test: 'test'
          }
        };
      } else {
        this.renderingData = null;
      }

    });
  }

  ngAfterContentInit(): void {
    const host = this.elementRef?.nativeElement as HTMLElement;

    this.ngZone.runOutsideAngular(() => {
      // aesthetical reasons for the timeout! The animation shall not happen when the component was just displayed
      setTimeout(() => this.utilCustomCSSPropertyService.setValue('--transitionDuration', '100ms', host), 1000);
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    const host = this.elementRef?.nativeElement as HTMLElement;
    host?.removeEventListener('input', this.inputFunction);
    this.typeChangeSubscription?.unsubscribe();
  }

  clickInnerIcon() {
    // iconType: 'none' | 'nullify' | 'clear' | 'custom' = 'none';
    if (this.iconType === 'none') {
      return;
    }
    if (this.iconType === 'nullify') {
      this.value = void 0;
      return;
    }
    if (this.iconType === 'clear') {
      this.value = '';
      return;
    }
    if (this.iconType === 'custom') {
      this.custom.emit(this.value);
      return;
    }
  }

  clickInfo(e: UIEvent) {
    this.info.emit(e);
  }

  onKeydownOfInput(e: UIEvent) {
    if (this.iconType === 'custom') {
      this.clickInnerIcon();
      return;
    }
  }

  handleDateFocus(event: FocusEvent) {
    this.testIfTransformNeeded(event);
    this.focus.emit(event);
  }

  handleDateBlur(event: FocusEvent) {
    this.testIfTransformNeeded(event);
    this.blur.emit(event);
  }

  getTextLengthString(): string {
    const len = this.value?.length || 0;
    const useMax = this.inputRestrictions.includes('valueGreaterThanMax');

    return `(${len}${useMax ? ('/' + this.max) : ''})`;
  }

  /**
   * a transform is needed if at least one of two things is true
   * - input element is focused
   * - input element has a value
   */
  private testIfTransformNeeded(event?: FocusEvent) {
    if (!this.showDynamicLabel) {
      return;
    }

    const needsToBeActive = event?.type === 'focus' || this.value;
    const needsToBeToggled = (this.labelTransformClassBehaviorSubject.value === 'dynamic-label-transform' && !needsToBeActive) || (this.labelTransformClassBehaviorSubject.value === '' && needsToBeActive);
    if (needsToBeToggled) {
      this.labelTransformClassBehaviorSubject.next((needsToBeActive ? 'dynamic-label-transform' : ''));
    }
  }
}
