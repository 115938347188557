/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Injector, OnDestroy, OnInit, Optional, inject } from '@angular/core';
import { InputDefaultRenderingData, INPUT_DEFAULT_RENDERING_INJECTION_TOKEN } from './input-rendering.types';
import { InputComponent } from './input.component';
import { TranslationService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilDatePickerService } from '@util/services/util-date-picker.service';
import { UtilDatePickerOptions } from '@util/services/util-date-picker-types';
import { map, take } from 'rxjs/operators';
import { getOneTimeTranslations } from '@util/functions/translations.functions';


@Component({
  selector: 'app-input-default-rendering',
  template: '{{InputRef?.value}}',
  styles: [':host { display: flex; width: 100%; margin: 2px 4px; border: 2px solid transparent; text-overflow: ellipsis; min-width: 200px; }']
})
export class InputDefaultRenderingComponent<T extends InputDefaultRenderingData> {

  private _data: T;
  private _inputComponentRef: InputComponent;

  @Optional() private injector = inject(Injector);

  get injectedData(): T {
    return this._data;
  }

  get InputRef(): InputComponent {
    return this._inputComponentRef;
  }

  isEmpty$: Observable<boolean>;

  constructor() {
    try {
        this._data = this.injector.get(INPUT_DEFAULT_RENDERING_INJECTION_TOKEN);
        this._inputComponentRef = this.injector.get(InputComponent);
    } catch (_) {
        // ignore exception
    }

    this.isEmpty$ = this.InputRef.formControl.valueChanges.pipe(map(val => !val));

  }

}


@Component({
  selector: 'app-input-date-rendering',
  template: `
    <div class="value {{((isEmpty$ | async) ? 'is-empty' : 'has-value')}}" tabindex="0" (activateElement)="openPicker($event)">{{(localizedValue$ | async) || ' '}}</div>
    <div class="icon" (activateElement)="openPicker($event)">
      <app-icon
        iconType="CALENDAR"
        iconSize="SMALL"
      ></app-icon>
    </div>
  `,
  styles: [`
    :host {

      --_placeholder: "";

      display: flex;
      align-items: center;
      width: 100%;
      margin: 2px 4px;
      border: 2px solid transparent;
      text-overflow: ellipsis;
      min-width: 200px;
      & > div:nth-child(1) {
        width: 100%;
        outline-offset: 1px;
        // adding text if value is empty so that the div increases the element to the normal height and does not inflate
        &.is-empty {
          &::before {
            content: var(--_placeholder);
            color: var(--kurz-color-grey-medium);
          }
        }
      }
      & > div:nth-child(2) {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          scale: 0.95;
        }
        ::ng-deep {
          --app-input-icon-background-color: transparent;
          --app-input-hover-color: transparent;
          --app-input-focused-color: transparent;
        }
      }
    }
  `]
})
export class InputDateRenderingComponent extends InputDefaultRenderingComponent<any> implements OnInit, OnDestroy {

  private windowRef = inject(WindowRef);
  private elementRef = inject(ElementRef);
  private utilDatePickerService = inject(UtilDatePickerService);
  private translationService = inject(TranslationService);

  private valueChangeSub: Subscription;
  private placeholderChangeSub: Subscription;

  localizedValue$ = new BehaviorSubject<string>('');

  constructor() {
    super();

    const locale = this.windowRef.nativeWindow.navigator.language;

    const getLocalizedDate = (iso: string): string => {
      const date = new Date(iso);
      const locStr = date.toLocaleDateString(locale, {day: '2-digit', 'month': '2-digit', year: 'numeric'});
      return locStr;
    };

    this.localizedValue$.next(getLocalizedDate(this.InputRef.value));

    this.valueChangeSub = this.InputRef.valueChange.subscribe(value => {
      if (value) {
        this.localizedValue$.next(getLocalizedDate(value));
      } else {
        this.localizedValue$.next('');
      }
    });

    this.placeholderChangeSub = this.InputRef.placeholder$.subscribe(placeholder => {
      (this.elementRef.nativeElement as HTMLElement).style.setProperty('--_placeholder', `"${placeholder || ''}"`);
    });
  }

  ngOnInit(): void {
    const host = this.elementRef.nativeElement as HTMLElement;
    host.addEventListener('focusin', this.onFocusIn);
    host.addEventListener('focusout', this.onFocusOut);
  }

  ngOnDestroy(): void {
    const host = this.elementRef.nativeElement as HTMLElement;
    host.removeEventListener('focusin', this.onFocusIn);
    host.removeEventListener('focusout', this.onFocusOut);

    this.valueChangeSub.unsubscribe();
    this.placeholderChangeSub.unsubscribe();
  }

  openPicker(event: Event) {

    const currentValue = this.InputRef.value;
    let current: Date;
    let minDate: Date;
    let maxDate: Date;

    // a function, which will be called with a newly created date and before it is saved (and used)
    // can be used to set a date to 12pm so that there will be no date shifts due to timezone offsets
    const beforeCreateDate = (date: Date) => {
      date.setHours(12, 0, 0, 0);
      return date;
    };

    if (this.InputRef.min) {
      minDate = beforeCreateDate(new Date(this.InputRef.min));
    }

    if (this.InputRef.max) {
      maxDate = beforeCreateDate(new Date(this.InputRef.max));
    }

    if (currentValue) {
      current = beforeCreateDate(new Date(currentValue));
    }

    getOneTimeTranslations(this.translationService, [
      'common.delete',
      'common.close',
      'common.today'
    ]).subscribe(translations => {

      const options: UtilDatePickerOptions = {
        startingPageOptions: {
          current,
          min: minDate,
          max: maxDate,
          selectableWeekdays: this.InputRef.disableWeekend ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6, 7], // NOSONAR
          beforeCreateDate,
        },
        anchorEl: this.elementRef.nativeElement as HTMLElement,
        openedBy: (event.type.includes('focus') || event.type.includes('key')) ? 'keyboard' : 'pointer',
        translations: {
          deleteAction: translations[0],
          closeAction: translations[1],
          todayAction: translations[2]
        }
      };

      const ref = this.utilDatePickerService.open(options);

      ref?.waitForResult().subscribe(cell => {
        if (cell) {
          this.InputRef.picked.emit(cell);
          const newValue = cell ? cell.iso : '';
          this.InputRef.formControl.setValue(newValue);
        }
      });

    });

  }

  onFocusIn = (e: FocusEvent) => {
    const el = e.target as HTMLElement;

    // waiting for element being in viewport then opening picker
    // this can be necessary if smooth scrolling is active
    const obs = new IntersectionObserver(entries => {
      // eslint-disable-next-line no-magic-numbers
      if (entries[0].intersectionRatio > 0.5) {
        obs.disconnect();
        this.openPicker(e);
      }
    }, {
      // eslint-disable-next-line no-magic-numbers
      threshold: [0.25, 0.45, 0.5, 0.75, 0.9, 1]
    });
    obs.observe(el);
    this.InputRef.focus.emit(e);
  };

  onFocusOut = (e: FocusEvent) => {
    this.utilDatePickerService.activeRef$.pipe(take(1)).subscribe(ref => ref?.close());
    this.InputRef.blur.emit(e);
  };

}
