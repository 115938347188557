/* eslint-disable @typescript-eslint/naming-convention */

import { InjectionToken, Injector } from '@angular/core';
import { InputDefaultRenderingComponent } from './input-default-rendering.component';
import { MenuComponent } from '../menu/menu.component';
import { Observable } from 'rxjs';
import { ConfigBeltSelectBox } from '../config-belt/config-belt.types';


export type InputDefaultRenderingComponentType<T = any> = new (...args: any[]) => InputDefaultRenderingComponent<T>;

export const INPUT_DEFAULT_RENDERING_INJECTION_TOKEN = new InjectionToken<any>('INPUT_DEFAULT_RENDERING_INJECTION_TOKEN');

export interface InputDefaultRenderingData {
  [key: string]: any;
}

export interface DropdownInputDefaultRenderingData extends InputDefaultRenderingData {
  getDropdownMenuRefObservable: () => Observable<MenuComponent>;
}

export interface ConfigBeltInputDefaultRenderingData extends DropdownInputDefaultRenderingData {
  box: ConfigBeltSelectBox;
}

export interface InputRenderingData<T = {[key:string]: any}> {
  component?: InputDefaultRenderingComponentType<T>;
  /**
   * injected data for the CheckboxComponent only
   * The ConfigBeltComponent, the DropdownComponent and the CheckboxGroupComponent defines data respectively in
   * ConfigBeltSelectOption.data; DropdownItem.data CheckboxItem.data;
   * (Their components will replace this "data" with the "data" of these collection types)
   */
  data?: Omit<T, 'box'>;
  /**
   * set by the component
   */
  __renderingInjector?: Injector;
}
